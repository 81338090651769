<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <PilotCard ref="preview" v-bind:title="title" v-bind:example="true">
          <template v-slot:title>
            <h3 class="card-title">
              <slot name="title">Waste Logs</slot>
            </h3>
          </template>
          <template v-slot:toolbar>
            <b-dropdown
              size="sm"
              variant="link"
              toggle-class="custom-v-dropdown btn btn-clean btn-light-primary btn-sm btn-icon"
              no-caret
              right
              no-flip
            >
              <template v-slot:button-content>
                <i class="ki ki-bold-more-hor"></i>
              </template>
              <div class="navi navi-hover min-w-md-250px">
                <b-dropdown-text tag="div" class="navi-item">
                  <router-link to="/waste-logging/create" v-slot="{ navigate }">
                    <div
                      class="d-flex align-items-center mb-5 cursor-pointer"
                      @click="navigate"
                    >
                      <div class="symbol symbol-40 symbol-light-info mr-5">
                        <span class="symbol-label">
                          <span class="svg-icon svg-icon-lg svg-icon-info">
                            <inline-svg
                              src="media/svg/icons/Code/Plus.svg"
                            ></inline-svg>
                          </span>
                        </span>
                      </div>
                      <div
                        class="d-flex flex-column font-weight-bold text-left"
                      >
                        <a
                          class="text-dark text-hover-primary mb-0 font-size-lg"
                        >
                          New Waste Log
                        </a>
                        <span class="text-muted">
                          Log waste
                        </span>
                      </div>
                    </div>
                  </router-link>
                </b-dropdown-text>
                <b-dropdown-text tag="div" class="navi-item">
                  <export-excel
                    class="border-0"
                    :data="logs"
                    :fields="fields"
                    type="csv"
                    worksheet="wastes"
                    name="wastes.csv"
                  >
                    <div class="d-flex align-items-center mb-5 cursor-pointer">
                      <div class="symbol symbol-40 symbol-light-warning mr-5">
                        <span class="symbol-label">
                          <span class="svg-icon svg-icon-lg svg-icon-warning">
                            <inline-svg
                              src="media/svg/icons/Files/Export.svg"
                            ></inline-svg>
                          </span>
                        </span>
                      </div>
                      <div class="d-flex flex-column font-weight-bold">
                        <a
                          class="text-dark text-hover-primary mb-0 font-size-lg"
                        >
                          Export Waste Log
                        </a>
                        <span class="text-muted">
                          Export to excel
                        </span>
                      </div>
                    </div>
                  </export-excel>
                </b-dropdown-text>
                <b-dropdown-text tag="div" class="navi-item">
                  <div
                    @click="$bvModal.show('searchModal')"
                    class="d-flex align-items-center mb-5 cursor-pointer"
                  >
                    <div class="symbol symbol-40 symbol-light-primary mr-5">
                      <span class="symbol-label">
                        <span class="svg-icon svg-icon-lg svg-icon-primary">
                          <inline-svg
                            src="media/svg/icons/General/Search.svg"
                          ></inline-svg>
                        </span>
                      </span>
                    </div>
                    <div class="d-flex flex-column font-weight-bold">
                      <a class="text-dark text-hover-primary mb-0 font-size-lg">
                        Advanced Search
                      </a>
                      <span class="text-muted">
                        Filter logs
                      </span>
                    </div>
                  </div>
                </b-dropdown-text>
                <b-dropdown-text tag="div" class="navi-item">
                  <div
                    @click="refresh()"
                    class="d-flex align-items-center mb-5 cursor-pointer"
                  >
                    <div class="symbol symbol-40 symbol-light-success mr-5">
                      <span class="symbol-label">
                        <span class="svg-icon svg-icon-lg svg-icon-success">
                          <inline-svg
                            src="media/svg/icons/Media/Repeat.svg"
                          ></inline-svg>
                        </span>
                      </span>
                    </div>
                    <div class="d-flex flex-column font-weight-bold">
                      <a class="text-dark text-hover-primary mb-0 font-size-lg">
                        Refresh
                      </a>
                      <span class="text-muted">
                        Refresh logs
                      </span>
                    </div>
                  </div>
                </b-dropdown-text>
              </div>
            </b-dropdown>
          </template>
          <template v-slot:body>
            <template>
              <div class="row">
                <div class="col-md-12 col-sm-12">
                  <b-form-input
                    placeholder="Quick Search"
                    v-model="quickSearch"
                  ></b-form-input>
                </div>
              </div>
              <hr />
              <v-data-table
                :headers="headers"
                :items="logs"
                :loading="dataLoading"
                loading-text="Loading Waste Logs..."
                :search="quickSearch"
                :footer-props="{
                  'items-per-page-options': [10, 20, 30, 40, 50]
                }"
                :items-per-page="20"
                class="elevation-1"
              >
                <template v-slot:[`item.amount`]="{ item }">
                  {{ item.amount }} {{ item.waste_stream.unit.measurement }}
                </template>

                <template v-slot:[`item.user`]="{ item }">
                  {{ item.user.firstname }} {{ item.user.lastname }}
                </template>

                <template v-slot:[`item.consumption_date`]="{ item }">
                  <span
                    ><time-ago
                      class="text-sm"
                      :refresh="60"
                      :datetime="item.consumption_date"
                      locale="en"
                      :long="true"
                      tooltip
                    ></time-ago
                  ></span>
                </template>

                <template v-slot:[`item.action`]="{ item }">
                  <div class="w-100 text-right">
                    <span
                      @click="viewDetail(item)"
                      v-b-tooltip.hover.top="'Details'"
                      class="btn btn-icon btn-light-primary btn-hover-light-primary btn-sm"
                    >
                      <span class="svg-icon svg-icon-md svg-icon-primary">
                        <inline-svg
                          src="media/svg/icons/Layout/Layout-horizontal.svg"
                        ></inline-svg>
                      </span>
                    </span>
                    <span
                      @click="editItem(item.id)"
                      v-if="hasPermission('Administrator, Editor')"
                      v-b-tooltip.hover.top="'Edit'"
                      class="btn btn-icon btn-light-warning btn-hover-light-warning btn-sm ml-3"
                    >
                      <span class="svg-icon svg-icon-md svg-icon-warning">
                        <inline-svg
                          src="media/svg/icons/Communication/Write.svg"
                        ></inline-svg>
                      </span>
                    </span>
                    <span
                      @click="deleteItem(item.id)"
                      v-if="hasPermission('Administrator, Editor')"
                      v-b-tooltip.hover.top="'Delete'"
                      class="btn btn-icon btn-light-danger btn-hover-light-danger btn-sm ml-3"
                    >
                      <span class="svg-icon svg-icon-md svg-icon-danger">
                        <inline-svg
                          src="media/svg/icons/General/Trash.svg"
                        ></inline-svg>
                      </span>
                    </span>
                  </div>
                </template>
              </v-data-table>
              <b-modal id="searchModal" hide-footer>
                <template #modal-title>
                  Advanced Search
                </template>
                <div class="d-block text-center">
                  <div class="mb-2">
                    <multiselect
                      v-model="stream"
                      :options="streams"
                      track-by="name"
                      label="name"
                      placeholder="Filter By Stream"
                    >
                      <template slot="singleLabel" slot-scope="{ option }">{{
                        option.name
                      }}</template>
                    </multiselect>
                  </div>
                  <div class="mb-2">
                    <multiselect
                      v-model="location"
                      :options="locations"
                      track-by="name"
                      label="name"
                      placeholder="Filter By Location"
                    >
                      <template slot="singleLabel" slot-scope="{ option }">{{
                        option.name
                      }}</template>
                    </multiselect>
                  </div>
                  <div class="mb-2">
                    <b-form-datepicker
                      :max="max"
                      v-model="startDate"
                      placeholder="Start Date"
                      class="text-left"
                    ></b-form-datepicker>
                  </div>
                  <div class="mb-2">
                    <b-form-datepicker
                      :max="max"
                      v-model="endDate"
                      placeholder="End Date"
                      class="text-left"
                    ></b-form-datepicker>
                  </div>
                </div>
                <b-button
                  class="mt-3"
                  block
                  variant="primary"
                  @click="advancedSearch()"
                  >SEARCH</b-button
                >
              </b-modal>
            </template>
          </template>
        </PilotCard>
      </div>
    </div>
    <b-modal ref="item-detail" hide-footer title="Waste Log Details">
      <div class="d-block">
        <div class="row">
          <div class="col-md-5 text-right">Waste Stream:</div>
          <div class="col-md-7 mb-3">
            <b>{{ waste_stream }} </b>
          </div>
          <div class="col-md-5 text-right">Location:</div>
          <div class="col-md-7 mb-3">
            <b>{{ waste_location }}</b>
          </div>
          <div class="col-md-5 text-right">Amount:</div>
          <div class="col-md-7 mb-3">
            <b>{{ waste_amount }} {{ waste_stream_unit }}</b>
          </div>
          <div class="col-md-5 text-right">Entry Date:</div>
          <div class="col-md-7 mb-3">
            <b>{{ waste_created_at | moment("dddd, MMMM Do, YYYY.") }}</b>
          </div>
          <div class="col-md-5 text-right">Created By:</div>
          <div class="col-md-7 mb-3">
            <b>{{ waste_creator }}</b>
          </div>
          <div class="col-md-12 d-flex justify-content-end">
            <b-button
              class="btn btn-light-danger font-weight-bold mt-3"
              @click="hideDetail"
              >Close</b-button
            >
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import PilotCard from "@/view/content/Card.vue";
import Multiselect from "vue-multiselect";
import WasteStreamService from "@/core/services/waste-stream.service.js";
import LocationService from "@/core/services/location.service.js";
import WasteService from "@/core/services/waste.service.js";
import { TimeAgo } from "vue2-timeago";
export default {
  props: {
    title: String
  },
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const maxDate = new Date(today);
    return {
      fields: {
        "Waste Stream": "waste_stream.name",
        "Location ": "location.name",
        "Timestamp ": "consumption_date",
        "Amount ": "amount",
        "Comment ": "comment"
      },
      headers: [
        {
          text: "Waste Stream",
          align: "start",
          sortable: false,
          value: "waste_stream.name"
        },
        { text: "Location", value: "location.name" },
        { text: "Amount", value: "amount" },
        { text: "Entry Date", value: "consumption_date" },
        { text: "Created By", value: "user" },
        { text: "", value: "action" }
      ],
      max: maxDate,
      logs: [],
      rowsPerPageItems: [20, 40, 80, 100],
      quickSearch: "",
      keyword: null,
      locations: [],
      streams: [],
      location: null,
      stream: null,

      waste_stream: null,
      waste_location: null,
      waste_amount: null,
      waste_consumption_date: null,
      waste_created_at: null,
      waste_creator: null,

      startDate: null,
      endDate: null,

      dataLoading: true
    };
  },
  components: {
    PilotCard,
    TimeAgo,
    Multiselect
  },
  methods: {
    getLocations() {
      LocationService.all()
        .then(response => {
          this.locations = response.data;
        })
        .catch(error => {
          this.makeToast(
            "danger",
            "Oops",
            "An unexpected exception has occured. Could not populate locations at the moment, please give it a minute and refresh page"
          );
          console.log(error);
        });
    },
    getStreams() {
      WasteStreamService.all()
        .then(response => {
          this.streams = response.data;
        })
        .catch(error => {
          this.makeToast(
            "danger",
            "Oops",
            "An unexpected exception has occured. Could not populate streams at the moment, please give it a minute and refresh page"
          );
          console.log(error);
        });
    },
    getColor(status) {
      if (status == "Open") return "bg-danger";
      else if (status == "Pending") return "bg-warning";
      else return "bg-success";
    },
    exportExcel() {
      if (status == "open") return "bg-danger";
      else if (status == "pending") return "bg-warning";
      else return "bg-success";
    },
    advancedSearch() {
      // eslint-disable-next-line prettier/prettier
      let slug = `?location=${this.location ? this.location.id : ""}&waste_stream=${this.stream ? this.stream.id : ""}&date_from=${this.startDate}&date_to=${this.endDate}`;
      this.getLogs(slug);
      this.$bvModal.hide("searchModal");
    },
    getLogs(slug) {
      WasteService.all(slug)
        .then(response => {
          this.logs = response.data;
          this.dataLoading = false;
        })
        .catch(error => {
          this.makeToast(
            "danger",
            "Oops",
            "An unexpected exception has occured. Could not populate waste accumulation logs at the moment, please give it a minute and refresh page"
          );
          console.log(error);
        });
    },
    editItem(id) {
      this.$router.push(`/waste-logging/edit/${id}`);
    },
    viewDetail(waste) {
      this.waste_stream = waste.waste_stream.name;
      this.waste_stream_unit = waste.waste_stream.unit.name;
      this.waste_location = waste.location.name;
      this.waste_amount = waste.amount;
      this.waste_consumption_date = waste.consumption_date;
      this.waste_created_at = waste.created_at;
      this.waste_creator = waste.user.firstname + " " + waste.user.lastname;
      this.$refs["item-detail"].show();
    },
    deleteItem(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "Are you sure you want to delete this waste log",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Delete",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return WasteService.delete(id)
            .then(response => {
              // eslint-disable-next-line prettier/prettier
              let slug = `?location=${this.location ? this.location.id : ""}&waste_stream=${this.stream ? this.stream.id : ""}&date_from=${this.startDate}&date_to=${this.endDate}`;
              this.getLogs(slug);
              return response.data;
            })
            .catch(error => {
              Swal.showValidationMessage(
                `Operation Failed: ${error.data.message}`
              );
            });
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then(result => {
        if (result.isConfirmed) {
          this.makeToast(
            "success",
            "Waste Log Deleted",
            "Waste log was successfully deleted"
          );
        }
      });
    },
    refresh() {
      this.logs = [];
      this.quickSearch = "";
      this.keyword = null;
      this.location = null;
      this.stream = null;
      this.startDate = null;
      this.endDate = null;
      this.dataLoading = true;
      this.getLogs();
    },
    makeToast(variant = null, title, message) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true
      });
    }
  },
  computed: {
    /**
     * Check if has title
     * @returns {boolean}
     */
    hasTitleSlot() {
      return !!this.$slots["title"];
    },
    ...mapGetters(["currentUser", "layoutConfig", "hasPermission"])
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Waste Logs" }]);
    this.getLocations();
    this.getStreams();
    this.getLogs();
  }
};
</script>
