import axios from "axios";

const WasteService = {
  /**
   * Creates new waste log
   * @param  {} params
   */
  create(params) {
    return new Promise((resolve, reject) => {
      axios
        .post("wastes", params)
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(new Error(error));
          }
        });
    });
  },

  /**
   * Gets a waste log
   * @param  {} id
   */
  get(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`wastes/${id}`)
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(new Error(error));
          }
        });
    });
  },

  /**
   * Get all waste logs
   * @param  {} slug
   */
  all(slug) {
    let url = slug ? `wastes?${slug}` : "wastes";
    return new Promise((resolve, reject) => {
      axios
        .get(url)
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(new Error(error));
          }
        });
    });
  },

  /**
   * Updates a waste log
   * @param  {} id
   * @param  {} params
   */
  update(id, params) {
    return new Promise((resolve, reject) => {
      axios
        .put(`wastes/${id}`, params)
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(new Error(error));
          }
        });
    });
  },

  /**
   * Deletes a waste log
   * @param  {} id
   */
  delete(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`wastes/${id}`)
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(new Error(error));
          }
        });
    });
  }
};

export default WasteService;
